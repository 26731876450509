document.addEventListener("DOMContentLoaded", () => {
    const menuWrappers = Array.from(document.querySelectorAll(".menu-wrapper"));
  
    //- Toggle sub-menus
    menuWrappers.forEach(menuWrapper => {
      const menu = menuWrapper.querySelector(".menu");
      menu.addEventListener("click", e => {
        menuWrapper.classList.toggle("is-closed");
      });
    });
});  