// auxiliary function closes the user profile menu
function closeUserControls() {
    let userControls = document.querySelector("#user-controls");
    if (userControls) userControls.removeAttribute("open");
  }
  
  function closeLeftMenu() {
    let leftMenu = document.querySelector("#main__menu");
    if (leftMenu) leftMenu.removeAttribute("open");
  }
  
  function closeRightMenu() {
    let rightMenu = document.querySelectorAll(".jsRightMenu");
    if (Array.from(rightMenu).filter(el => el.hasAttribute("open")).length > 0) {
      Array.from(document.querySelectorAll(".views-container")).forEach(vC =>
        vC.classList.toggle("sidebar-is-closed")
      );
      Array.from(rightMenu).forEach(el => el.removeAttribute("open"));
    }
  }
  
  function openRightMenu() {
    let rightMenu = document.querySelectorAll(".jsRightMenu");
    Array.from(rightMenu).forEach(el => el.setAttribute("open", ""));
    Array.from(document.querySelectorAll(".views-container")).forEach(vC =>
      vC.classList.toggle("sidebar-is-closed")
    );
  }

document.addEventListener("DOMContentLoaded", function() {
  
    //- View change event
    window.addEventListener("navigate", () => {
      closeLeftMenu();
      closeUserControls();
    });
    // Document -> close menu
    document.addEventListener("click", event => {
      if (!event.target.closest("#user-controls")) {
        closeUserControls();
      }
      if (
        !event.target.closest("#main__menu") &&
        event.target.id != "toggleMainMenu"
      ) {
        closeLeftMenu();
      }
      if (
        !event.target.className.includes("jsMobileSidebarOpenButton") &&
        !event.target.className.includes("jsOffsiteToggle")
      ) {
        closeRightMenu();
      }
    });

    // listen for keypress
    document.onkeydown = e => {
      e = e || window.event;
      if (e.key === "Escape" || e.key === "Esc") {
        closeUserControls();
        closeLeftMenu();
        closeRightMenu();
      }
    };

    document.querySelector("#toggleMainMenu").addEventListener("click", () => {
      let leftMenu = document.querySelector("#main__menu");
      if (leftMenu.hasAttribute("open")) {
        closeLeftMenu();
      } else {
        leftMenu.setAttribute("open", "");
      }
    });

    const rightMenus = Array.from(document.querySelectorAll("nav.jsRightMenu"));
    rightMenus.forEach(rightMenu => {
      const btnRightMenu = rightMenu.querySelector("li.jsOffsiteToggle");
      btnRightMenu.addEventListener("click", () => {
        if (rightMenu.hasAttribute("open")) {
          closeRightMenu();
        } else {
          openRightMenu();
        }
      });
    });

    Array.from(document.querySelectorAll(".jsMobileSidebarOpenButton")).forEach(
      el => {
        el.addEventListener("click", () => {
          openRightMenu();
        });
      }
    );
});