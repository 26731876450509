if('serviceWorker' in navigator) {
  var refreshing;
  navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) {
          return;
      }
      refreshing = true;
      window.location.reload();
  });
  navigator.serviceWorker.register('/sw.js');
}