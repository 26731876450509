document.addEventListener("DOMContentLoaded", function () {
  document
    .querySelector("sib-auth")
    .getUser()
    .then(user => {
      if (user !== null) {
        document
          .querySelectorAll(".notLoggedIn")
          .forEach(el => (el.style.visibility = "visible"));
        document
          .querySelector('.loggedIn')
          .setAttribute("style", "display:none !important");
      }
    });
});