document.addEventListener("DOMContentLoaded", () => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    document.addEventListener("save", () => {
        Toast.fire({
            icon: 'success',
            title: 'Succès!'
        })
    });
});