document.addEventListener("DOMContentLoaded", function () {
  window.addEventListener('newMessage', event => {
    let jid = event.detail.jid;
    Array.from(document.querySelectorAll('[data-jabberID="' + jid + '"]')).forEach(el => {
      el.parentElement.parentElement.classList.add('unread');
    });
  });

  window.addEventListener('read', (event) => {
    if (event.detail && event.detail.resource && event.detail.resource['@id']) {
      const badge = document.querySelector(`solid-badge[data-src="${event.detail.resource['@id']}"]`);
      if (badge) {
        badge.parentElement.parentElement.classList.remove('unread');
        const project = badge.parentElement.parentElement.querySelector('.unread');
        if (project) project.classList.remove('unread');
      }

    }
  });
});