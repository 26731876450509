function applyAdapt(prefix, sibDisplay, user) {

  //- Allow to bind-user on selected attribute
  if (sibDisplay.getAttribute(prefix + "-user-id")) {
    sibDisplay.setAttribute(
      sibDisplay.getAttribute(prefix + "-user-id"),
      user["@id"]
    );
  }

  //- Allow to set data-src to a children solid-form
  if (sibDisplay.getAttribute(prefix + "-bind-resources")) {
    let form = sibDisplay.querySelector(
      sibDisplay.getAttribute(prefix + "-bind-resources") + " solid-form"
    );
    if (form) {
      form.setAttribute(
        "data-src",
        sibDisplay.component.resourceId.replace("members/", "")
      );
    }
  }

  //- Allow to put user on a targetted search field
  if (sibDisplay.getAttribute(prefix + "-bind-user2input")) {
    let qS = sibDisplay.querySelector(
      sibDisplay.getAttribute(prefix + "-bind-user2input")
    );
    if(qS) qS.value = user["@id"];
  }
}

//- This function is a workaround for the currently unworking populate
//- Feel free to see examples on page-circles-
function recursiveAdaptWidgets(prefix, element, user) {
  element.addEventListener("populate", () => {
    element.querySelectorAll("[" + prefix + "-user-id]").forEach(el => {
      el.setAttribute(el.getAttribute(prefix + "-user-id"), user["@id"]);
    });
    applyAdapt(prefix, element, user);
    document.querySelectorAll('solid-display, solid-form').forEach(sibDisplay => {
      applyAdapt(prefix, sibDisplay, user);
    });
  });
}

document.addEventListener("DOMContentLoaded", function () {
  document
    .querySelector("sib-auth")
    .getUser()
    .then(user => {
      if (user !== null) {
        document.querySelectorAll('solid-display, solid-form').forEach(element => {
          // Set current user id on set-user-id of solid-display
          recursiveAdaptWidgets("hubl-inherit", element, user);
        });
        for (leaveBtn of document.querySelectorAll(
            "admin-circle-leave > solid-ac-checker:not([hidden])"
          )) {
             // Hide Join button
          leaveBtn.parentNode.parentNode.parentNode.nextElementSibling.setAttribute(
            "style",
            "display:none !important"
          );
        }
      }
    });
});